import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner } from "reactstrap";
import { Redirect } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "../countries";

function Register({ type }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  let utmSource;
  if (utm_source) {
    localStorage.setItem("utmSource", utm_source);
    utmSource = utm_source;
  } else {
    utmSource = localStorage.getItem("utmSource");
  }
  if (!utmSource) {
    utmSource = 'Direct';
  }

  const searchParams = new URLSearchParams(window.location.search);
  const editionLink = searchParams.get('edition');

  const [details, setDetails] = React.useState({
    type,
    // optIn: true,
    // privacyPolicy: true,
    marketing: 'No',
    consent: 'No',
    utmSource,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    title: '',
    country: '',
    edition: editionLink === 'November2025' ? edition[1] : edition[0],
  });
  const [errorObj, setErrorObj] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeDetails = (e) => {
    setErrorObj({});
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const validateInputs = () => {
    // const reg =
    //   /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

    if (!details.firstName) {
      setErrorObj({ ...errorObj, first_name: "Name is required." });
      return false;
    }
    if (!details.lastName) {
      setErrorObj({ ...errorObj, last_name: "Name is required." });
      return false;
    }
    if (!details.email) {
      setErrorObj({ ...errorObj, email: "Enter Valid Email Address." });
      return false;
    }
    if (!details.phone) {
      setErrorObj({ ...errorObj, phone: "Phone is required." });
      return false;
    }
    if (!details.title) {
      setErrorObj({ ...errorObj, job: "Job Title is required." });
      return false;
    }
    if (!details.company) {
      setErrorObj({ ...errorObj, company: "Company is required." });
      return false;
    }
    if (!details.country) {
      setErrorObj({ ...errorObj, email: "Select a country from the dropdown." });
      return false;
    }

    return true;
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const validateInp = validateInputs();
    if (!validateInp) {
      setLoading(false);
      setError("Please correct one or more fields above.");
      return null;
    }

    fetch("https://api.khaleejtimesevents.com/bitDubai/rg.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        if (type === "REPORT") {
          window.location.href =
            "https://drive.google.com/uc?id=1Qgwi4cE94CvFVjlDCxDS_5vGoJI2oB6L&export=download";
          setSuccess(
            "https://drive.google.com/uc?id=1Qgwi4cE94CvFVjlDCxDS_5vGoJI2oB6L&export=download"
          );
        } else {
          setRedirect(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  let inputs = content;
  if (redirect) {
    return <Redirect to={`/dubai/thank-you/${type}`} />;
  }

  const ErrorComp = ({ error }) => (
    <span
      style={{
        background: "#f8b411",
        color: "#fff",
        padding: "2px",
        marginTop: "2px",
      }}
    >
      {error}
    </span>
  );

  return (
    <div
      style={{
        backgroundColor: "#fff",

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
      className="section py-3"
    >
      <Container>
        <h3 className="text-center">{details.edition}</h3>
        <Row>
          <Col className="mx-auto mb-5 py-5" lg={6}>
            <Container className="my-auto text-400 text-dark">
              <Form onSubmit={onSubmitForm} className={editionLink ? 'register-form' : 'register-form'}>
                <Row>
                  {inputs.map((i) =>
                    i.name !== "phone" ? (
                      <Col lg={6} className="my-2" key={i.name}>
                        <label>{i.placeholder}*</label>
                        <Input
                          placeholder={i.placeholder}
                          type={i.type}
                          name={i.name}
                          onChange={onChangeDetails}
                          required
                        />
                        {errorObj[i.name] && <ErrorComp error={errorObj[i.name]} />}
                      </Col>
                    ) : (
                      <Col lg={6} className="my-2">
                        <label>Phone*</label>
                        <PhoneInput
                          country={"ae"}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          inputClass="w-100"
                          dropdownClass="text-white"
                          value={details.phone}
                          onChange={(phone) => {
                            setDetails({ ...details, phone });
                          }}
                        />
                        {errorObj.phone && <ErrorComp error={errorObj.phone} />}
                      </Col>
                    )
                  )}
                  <Col lg={6} className="my-2">
                    <label>Country *</label>
                    <Input
                      placeholder="Select"
                      type="select"
                      name="country"
                      onChange={onChangeDetails}
                      required
                    >
                      <option value="">Select</option>
                      {countries.map((c) => (
                        <option>{c}</option>
                      ))}
                    </Input>
                    {errorObj.country && <ErrorComp error={errorObj.country} />}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Edition *</label>
                    <Input
                      type="select"
                      name="edition"
                      id="edition"
                      value={details.edition}
                      onChange={onChangeDetails}
                      required
                    >
                      <option value="">SELECT</option>
                      {edition.map((s) => (
                        <option key={s}>{s}</option>
                      ))}
                    </Input>
                  </Col>

                  <Col lg={12} className="text-left pt-2">
                    <p style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='marketing'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, marketing: 'Yes' });
                          } else {
                            setDetails({ ...details, marketing: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I would like to receive marketing communications on products, services or events.
                    </p>
                  </Col>

                  <Col lg={12} className="text-left pt-2">
                    <p style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='consent'
                        required
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, consent: 'Yes' });
                          } else {
                            setDetails({ ...details, consent: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I provide consent to Galadari Printing and Publishing to process my personal data in line with the{" "}
                      <a href="https://www.khaleejtimes.com/privacy-notice" style={{ color: 'rgb(87, 200, 232)' }}>Privacy Notice</a>
                    </p>
                  </Col>

                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    type="submit"
                    disabled={loading || details.consent !== 'Yes'}
                    className="text-600 px-4 w-100"
                    color="primary"
                    size="lg"
                    style={{
                      backgroundImage: "linear-gradient(315deg, #429f50 0%, #000000 96%)",
                      border: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        color: "#fff",
                      }}
                    >
                      SUBMIT
                    </span>
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} type={type} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error, type }) {
  const color = success ? "success" : "danger";

  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {error ? (
          error
        ) : (
          <>
            <a href={success} target="_blank" rel="noopener noreferrer" className="text-white">
              Click here
            </a>{" "}
            if your download does not begin.
          </>
        )}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "firstName", type: "text", placeholder: "First Name" },
  { id: 1, name: "lastName", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "email", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "title", type: "text", placeholder: "Job Title" },
];

const edition = [
  "BIT'24 Dubai - November 2024",
  "BIT'25 Dubai - November 2025",
];