import React from "react";

// core components
import Navbar from "../componentsDubai/Navbar.js";
import LandingPageHeader from "componentsDubai/Headers/LandingPageHeader.js";

import SponsorNew from "componentsDubai/SponsorNew.js";
import SpeakerCard from "componentsDubai/SpeakerCard.js";
import SpeakerCardPast from "componentsDubai/SpeakerCardPast.js";

import PastSponsors from "componentsDubai/PastSponsors.js";
import DemoFooter from "../componentsDubai/Footer.js";

// import Agenda from "../componentsRiyadh/Agenda.js";
// import Photos from "../componentsRiyadh/Photos.js";
import TextGrib5 from "../componentsDubai/TextGrid5.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
//   const size =
//     document.body.clientWidth >= 1024 ||
//     document.documentElement.clientWidth >= 1024
//       ? true
//       : false;
  return (
    <div
      style={{
        backgroundColor: "transparent",

        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Navbar />
      <section id="home" />
      <LandingPageHeader />

      {/* <TextGrid4 /> */}
      <TextGrib5 />

      {/* <section id="agenda">
        <Agenda />
      </section> */}
      {/* <Photos /> */}
      <section id="speakers">
        <SpeakerCard />
      </section>

      <section id="partners">
        <SponsorNew />
      </section>
      <section id="speakers">
        <SpeakerCardPast />
      </section>
      
      <section id="partners">
        <PastSponsors />
      </section>
      

      <DemoFooter />
    </div>
  );
}

export default Home;
